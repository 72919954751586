<template>
  <div v-if="currentUser.superAdmin">
    <v-data-table
        :headers="headers"
        :items="memberships"
        :options.sync="pagination"
        :server-items-length="totalMemberships"
        :loading="loading"
        :footer-props="{
        'showFirstLastPage':true,
        'items-per-page-options': [10, 20, 50, 100]
      }"
        class="elevation-1"
    >
      <template v-slot:item.id="{ item }">
        <v-menu :close-on-content-click=false bottom offset-y>
          <template v-slot:activator="{ on }">
            <v-icon small class="mr-2" v-on="on"
            >mdi-focus-field-horizontal
            </v-icon>
          </template>
          <v-list dense style="max-height: 500px" class="overflow-y-auto">
            <v-list-item >
              <v-list-item-title>{{ item.id }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:item.redeem="{item}">
        <router-link
            v-if="item.haveRedeems"
            :to="{
              name: 'ShopRedeems',
              params: { shopId: item.shopId }
            }"
        >
          <v-avatar color="primary" size="26" rounded>
            <v-icon dark :disabled="loading">mdi-eye</v-icon>
          </v-avatar>
        </router-link>
      </template>

      <template v-slot:item.commission="{item}">
        <v-btn
            outlined @click="showCommissionChangePopup(item)">
          {{ item.commission != null ? item.commission + "%" : "0%" }}
        </v-btn>
      </template>

      <template v-slot:item.voucher="{item}">
        <v-btn
            outlined @click="showVoucherAddPopup(item)">
          {{ item.voucher + "€" }}
        </v-btn>
      </template>

      <template v-slot:item.package="{ item }">
        <v-menu bottom offset-x
                :nudge-width="200">
          <template v-slot:activator="{ on }">
            <v-btn outlined @click="loadPackageDetails(item)"
                   v-on="on">
              {{ item.subscriptionName + " " + item.subscriptionTimeIntervalName }}
            </v-btn>
          </template>
          <v-list v-if="packageDetails != null" dense style="max-height: 500px" class="overflow-y-auto">
            <v-list-item>
              <v-list-item-title>{{ $i18n.t('common.name') }}</v-list-item-title>
              <v-list-item-subtitle>{{ packageDetails.name }}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>{{ $i18n.t('common.installmentDuration') }}</v-list-item-title>
              <v-list-item-subtitle>{{ packageDetails.installmentDuration }}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>{{ $i18n.t('common.price') }}</v-list-item-title>
              <v-list-item-subtitle>{{ packageDetails.price + " " + packageDetails.currency }}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>{{ $i18n.t('common.oobleeCoins') }}</v-list-item-title>
              <v-list-item-subtitle>{{ packageDetails.oobleeCoins }}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>{{ $i18n.t('common.advertisementOobleeCoins') }}</v-list-item-title>
              <v-list-item-subtitle>{{ packageDetails.advertisementOobleeCoins }}</v-list-item-subtitle>
            </v-list-item>
          </v-list>

          <v-card>
            <v-list v-if="packageDetails != null">

              <v-card-text>{{ $i18n.t('common.permissions') }}</v-card-text>
              <v-list-item
                  v-for="(item, i) in packageDetails.permissionNames"
                  :key="i"
              >
                <v-list-item-title>{{ item }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>

        </v-menu>

      </template>
      <template v-slot:item.created="{ item }">
        {{ parseDate(item.created) }}
      </template>
      <template v-slot:item.validTo="{ item }">
        {{ parseDate(item.validTo) }}
      </template>
      <template v-slot:item.lastPaymentDate="{ item }">
        {{ parseDate(item.lastPaymentDate) }}
      </template>
      <template v-slot:item.active="{ item }">
        <v-switch
            v-model="item.active" disabled
        ></v-switch>
      </template>
      <template v-slot:item.autoRenewal="{ item }">
        <v-switch
            v-model="item.autoRenewal" disabled
        ></v-switch>
      </template>
      <template v-slot:item.invoiceUrl="{ item }">
        <v-btn v-if="item.invoiceUrl != null"
               class="ma-2"
               color="blue lighten-2"
               dark
               :href="item.invoiceUrl"
        >
          <v-icon dark>
            mdi-receipt
          </v-icon>
        </v-btn>
      </template>
      <template v-slot:item.invoiceStatus="{ item }">
        <v-chip
            class="ma-2"
            color="green"
            text-color="white"
            v-if="item.invoiceStatus === 'SUCCESS'"
        >
          {{ item.invoiceStatus }}
        </v-chip>
        <v-chip
            class="ma-2"
            color="red"
            text-color="white"
            v-if="item.invoiceStatus === 'ERROR'"
        >
          {{ item.invoiceStatus }}
        </v-chip>
        <v-chip
            class="ma-2"
            color="lime darken-1"
            text-color="white"
            v-if="item.invoiceStatus === 'PENDING'"
        >
          {{ item.invoiceStatus }}
        </v-chip>
        <v-chip
            class="ma-2"
            color="orange darken-1"
            text-color="white"
            v-if="item.invoiceStatus === 'CANCELED'"
        >
          {{ item.invoiceStatus }}
        </v-chip>
        <v-chip
            class="ma-2"
            color="blue-grey darken-1"
            text-color="white"
            v-if="item.invoiceStatus === 'STALLED'"
        >
          {{ item.invoiceStatus }}
        </v-chip>
        {{
          item.invoiceStatus != 'SUCCESS' && item.invoiceStatus != 'ERROR' && item.invoiceStatus != 'PENDING' &&
          item.invoiceStatus != 'CANCELED' && item.invoiceStatus != 'STALLED' ? item.invoiceStatus : ""
        }}
      </template>
      <template v-slot:body.prepend>
        <tr>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-text-field v-model="shopId" type="text"
                          @keyup.enter="page = 1;retrieveSubscriptions();"></v-text-field>
          </td>
          <td>
            <v-text-field v-model="shopName" type="text"
                          @keyup.enter="page = 1;retrieveSubscriptions();"></v-text-field>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>

            <v-row>
              <v-col>
                <v-select
                    :items="haveRedeemsList"
                    v-model="haveRedeems"
                    dense
                    hide-details
                    @keyup.enter="page = 1;retrieveSubscriptions();"
                ></v-select>
              </v-col>
              <v-col>
                <v-avatar color="indigo" size="30">
                  <v-icon
                      dark
                      @click="
                  page = 1;
                  retrieveSubscriptions();
                "
                  >mdi-magnify
                  </v-icon>
                </v-avatar>
              </v-col>
              <v-col>
                <v-avatar color="grey" size="30">
                  <v-icon
                      dark
                      @click="
                  page = 1;
                  clearSearch();
                "
                  >mdi-close
                  </v-icon>
                </v-avatar>
              </v-col>
            </v-row>
          </td>
        </tr>
      </template>
    </v-data-table>

    <v-dialog
        v-model="dialog"
        max-width="200px"
        persistent
    >
      <v-card class="mx-auto"
              max-width="344"
              outlined>

        <v-list>
          <v-list-item>
            <v-text-field
                type="number"
                v-model.number="commissionForEdit"
                :label="$t('pages.membership.commission')"
            ></v-text-field>
          </v-list-item>
        </v-list>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
              text
              @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
              color="primary"
              text
              @click="dialog = false;saveNewCommission(commissionForEdit, memberShipForEdit);"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="dialogVoucher"
        max-width="300px"
        persistent
    >
      <v-form ref="form" v-model="validVoucher" lazy-validation @submit.prevent="validateAndSave(voucherReason,voucherValue, memberShipForEdit)">
      <v-card class="mx-auto"
              max-width="344"
              outlined>

        <v-list>
          <v-list-item>
            <v-text-field
                v-model="voucherReason"
                :label="$t('pages.membership.reason')"
            ></v-text-field>
          </v-list-item>
          <v-list-item>
            <v-text-field
                type="number"
                :rules="voucherRule"
                min="0"
                v-model.number="voucherValue"
                :label="$t('pages.membership.voucherEuro')"
            ></v-text-field>
          </v-list-item>
        </v-list>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
              text
              @click="dialogVoucher = false"
          >
            Cancel
          </v-btn>
          <v-btn
              color="primary"
              text
              type="submit"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import moment from "moment";
import SubscriptionApiService from "@/core/services/api.service.subscription";
import {mapGetters} from "vuex";
import MembershipApiService from "@/core/services/api.service.membership";
import WalletApiService from "@/core/services/api.service.wallet";

export default {
  name: "shop-information",
  computed: {
    ...mapGetters(["currentUser"]),
    activeSubscriptionsList() {
      return [this.$i18n.t("common.all"), this.$i18n.t("common.no"), this.$i18n.t("common.yes"),]
    },
    headers() {
      return [
        {text: "Id", align: "start", value: "id", sortable: false},
        {text: this.$i18n.t('pages.membership.shopId'), value: "shopId", sortable: false},
        {text: this.$i18n.t('pages.membership.shopName'), value: "shopName", sortable: false},
        {text: this.$i18n.t('pages.membership.package'), value: "package", sortable: false},
        {text: this.$i18n.t('pages.membership.commission'), value: "commission", sortable: false},
        {text: this.$i18n.t('pages.membership.voucher'), value: "voucher", sortable: false},
        {text: this.$i18n.t('pages.membership.redeem'), value: "redeem", sortable: false, width: "15%"},
      ]
    }
  },
  data() {
    return {
      pageSizes: [10, 20, 50, 100],
      validVoucher: false,
      totalMemberships: 0,
      memberships: [],
      loading: false,
      subscriptionApiInitiated: false,
      pagination: {},
      loader: null,
      activeSubscriptions: "",
      errors: [],
      packageDetails: null,
      dialog: false,
      dialogVoucher: false,
      fav: true,
      memberShipForEdit: null,
      commissionForEdit: null,
      voucherValue: null,
      voucherReason: null,
      shopName: null,
      shopId: null,
      haveRedeems: "",
      haveRedeemsList: ["All", "No", "Yes"],
      numberRule: v => {
        console.log("v"+v);
        if (!v.trim()) return true;
        if (!isNaN(parseFloat(v)) && v >= 0 && v <= 999) return true;
        return 'Number has to be between 0 and 999';
      },
      voucherRule: [
        v => !!v || "Field is required",
        v => ( v && v > 0 ) || "Voucher should be a positive number",
      ],
      requiredRule: [v => !!v || "Field is required"]
    };
  },
  watch: {
    pagination: {
      handler() {
        if (this.subscriptionApiInitiated) this.retrieveSubscriptions();
      },
      deep: true
    }
  },
  async created() {
    if (!this.currentUser.superAdmin) return;
    this.loading = true;
    await SubscriptionApiService.init();
    await MembershipApiService.init();
    await WalletApiService.init();
    this.subscriptionApiInitiated = true;
    this.retrieveSubscriptions();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: "Shop Information"}]);
  },
  methods: {
    validateAndSave(voucherReason, voucherValue, item) {
      if (this.$refs.form.validate()) {
        this.saveNewVoucher(voucherReason, voucherValue, item);
      }
    },
    resetVoucherFields(){
      this.voucherValue = null;
      this.voucherReason = null;
    },
    showVoucherAddPopup(item) {
      this.memberShipForEdit = item;
      this.dialogVoucher = true
    },
    showCommissionChangePopup(item) {
      this.memberShipForEdit = item;
      this.commissionForEdit = item.commission;
      this.dialog = true
    },
    saveNewVoucher(voucherReason, voucherValue, item) {
      let voucher = {};
      voucher["shopId"] = item.shopId.toString();
      voucher["value"] = voucherValue;
      voucher["name"] = voucherReason;
      if (item.id != null) {
        WalletApiService.post(`/CreateAndRedeemWalletVoucherForShop`, voucher)
            .then(response => {
              this.$log.debug("Voucher added: " + response.data);
            })
            .catch(error => {
              this.$log.error("Error: ", error);
              this.errored = true;
            })
            .finally(() => {
              this.retrieveSubscriptions();
              this.dialogVoucher = false;
              this.resetVoucherFields();
            });
      }
    },
    saveNewCommission(commission, item) {
      console.log("ID:" + item.id);
      console.log("Commission:" + commission)
      if (item.id != null) {
        SubscriptionApiService.put(`/ChangeMembershipFee?membershipId=${item.id}&fee=${commission}`)
            .then(async responseShopApiService => {
              await this.$log.debug("Commission changed: " + responseShopApiService);
            })
            .catch(error => {
              this.$log.error("Error: ", error);
              this.errored = true;
            })
            .finally(() => (this.retrieveSubscriptions(), this.dialog = false));
      }
    },
    loadPackageDetails(item) {
      console.log("Item:" + item);
      MembershipApiService.get(`/CatalogItems/GetBundleById`, `${item.subscriptionDefinitionId}`)
          .then(response => {
            this.$log.debug("Package details: ", response.data);
            this.packageDetails = response.data;
            this.packageDetails.price = (response.data != null && response.data.catalogItemPrices.length > 0 ? response.data.catalogItemPrices[0].price : 0);
            this.packageDetails.currency = (response.data != null && response.data.catalogItemPrices.length > 0 ? response.data.catalogItemPrices[0].currency : 0);
            if (this.packageDetails.installmentDurationInDays === 365) this.packageDetails.installmentDuration = "YEARLY";
            if (this.packageDetails.installmentDurationInDays === 30) this.packageDetails.installmentDuration = "MONTHLY";
            let permissionNames = this.packageDetails.permissions.map(function (item) {
              return item['externalId'];
            });
            this.packageDetails.permissionNames = permissionNames;
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.errored = true;
          })
          .finally(() => (this.loading = false));
    },
    parseDate(timestamp) {
      return moment
          .utc(timestamp)
          .local()
          .format("yyyy-MM-DD HH:mm:ss");
    },

    getRequestParams(
        shopId,
        shopName,
        haveRedeems
    ) {
      let params = {};

      if (shopId) {
        params["shopId"] = shopId;
      }

      if (shopName) {
        params["shopName"] = shopName;
      }

      if (haveRedeems && (haveRedeems !== this.$i18n.t("common.all"))) {
        if (haveRedeems === this.$i18n.t("common.yes")) {
          params["haveRedeems"] = true;
        } else if (haveRedeems === this.$i18n.t("common.no")) {
          params["haveRedeems"] = false;
        }
      }


      let sort;
      let sortBy = this.pagination.sortBy;
      let sortDesc = this.pagination.sortDesc;
      if (sortBy.length === 1 && sortDesc.length === 1) {
        sort = sortBy[0];
        if (sortDesc[0] === true) sort += ",desc";
      }
      params["sort"] = sort;
      params["page"] = this.pagination.page;
      params["size"] = this.pagination.itemsPerPage;

      return params;
    },
    clearSearch() {
      this.shopId = "";
      this.shopName = "";
      this.haveRedeems = "";
    },
    retrieveSubscriptions() {
      const params = this.getRequestParams(
          this.shopId,
          this.shopName,
          this.haveRedeems,
      );
      this.$log.debug("params: ", params);
      return new Promise(resolve => {
        this.loading = true;
        SubscriptionApiService.query("/GetAllSubscriptionsForAdminPanel",
            params
        ).then(response => {
          // JSON responses are automatically parsed.
          this.$log.debug("MembershipsWithDetails response: ", response.data);
          this.memberships = response.data.content;
          this.totalMemberships = response.data.totalElements;
          this.totalPages = response.data.totalPages;
          this.loading = false;
          let title = this.totalMemberships;
          this.$store.dispatch(SET_BREADCRUMB, [{title: "Shops Information: " + title}]);
          resolve();
        });
      });
    }
  }
};


</script>
<style>
.v-data-table.v-data-table.v-data-table >>> td {
  font-size: 16px;
}

.v-icon.v-icon {
  font-size: 24px !important;
}

tbody tr:nth-of-type(even) {
  background-color: rgba(236, 237, 237);
}

tbody tr:nth-of-type(odd) {
  background-color: rgb(250, 250, 250);
}

tbody tr {
  width: 100%;
}

.filter {
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: center;
  background-color: aliceblue;
  border: 1px solid brown;
}

.search {
  display: flex;
  justify-content: space-around;
  padding: 20px;
}

.search-item {
  justify-content: center;
  display: flex;
}

</style>
